import { getUserAnalytics } from "../../../utils/storage";


export const handleEventOccurance = (id, component_name,event_type, user) => {
    console.log(user,'This is user');
    const userData=getUserAnalytics()
    console.log(userData,'THIS IS DATA FROM LOCAL STORAGE');
    console.log(userData.data.session.id,'SESSION ID');
    
    const sessionId = userData?.data?.session?.id
    const userId=userData?.data?.user?.userId
    const element = document.getElementById(id);
    let x_axis;
    let y_axis;
    if (element) {
        const rect = element.getBoundingClientRect();
        x_axis = rect.left + window.scrollX;
        y_axis = rect.top + window.scrollY;
    }

    if(!userId){
        console.log('user Id not found!')
        return false
    }
    if (!sessionId) {
        console.log('Session Id not found!')
        return false
    } else {
        const data = {
            userId:userId,
            component_name,
            event_type,
            sessionId,
            x_axis,
            y_axis
        }

        console.log("final data:", data)
        return data
        //setUpdateUserEvents(data)
        //setCheckComponentClick(true);
    }

}